/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type / create the client used to communicate to the API.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to type theme objects for MUI.
 */
import { ThemeOptions, Theme, createTheme } from '@mui/material';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type form metadata
 */
import * as Dtos from './api/dtos';
import { IUserResponse } from './hooks/useAuthenticatedUser';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the route parameters that are used by
 * the OPMS.
 */
interface IFormsRouteParameterOptions {
    
    /**
     * The route parameter name for Form Definition ID
     */
    formDefinitionId: string;

    /**
     * The route parameter name for Form Definition Code
     */
    formDefinitionCode: string;

    /**
     * The route parameter name for Form ID
     */
    formId: string;
    /**
     * The route parameter name for Form Repeat
     */
    formRepeat: string;
};

interface IFormsThemeOptions {
    beforeThemeCreated?: (themeOptions: ThemeOptions) => ThemeOptions;
    afterThemeCreated?: (theme: Theme) => Theme;
}

interface IFormsOrgOptions {
    organisationLogo?: string;
    organisationName?: string;
}

export interface IFormsOptions {

    initialAuthentication?: IUserResponse | null;

    /**
     * The base route of the apis (used to create keys for SWR).
     */
    baseRoute?: string | null;

    /**
     * The ServiceStack client to use throughout the application.
     *
     * (Automatically created if not provided).
     */
    serviceStackClient?: JsonServiceClient | null;

    /**
     * The configuration options for route parameters used throughout the app.
     *
     * (Defaults used if not provided).
     */
    routeParameters?: Partial<IFormsRouteParameterOptions> | null;

    /**
     * The configuration options to use when creating the theme.
     *
     * (Defaults used if not provided).
     */
    themeOptions?: Partial<IFormsThemeOptions> | null;

    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    dtos: Record<string, any>;

    /**
     * The configuration options for the trial name and logo.
     *
     * (Defaults used if not provided).
     */
    organisationOptions?: IFormsOrgOptions | null;

    formMetadata?: Dtos.FormMetadata[] | null;

    extensions?: IFormsExtension[] | null;
}

export interface IFormsExtension {
    initialiseReducers?: (opms: Forms) => void;
    ProviderComponent?: React.ComponentType<{ children: React.ReactNode }>;
    ContentComponent?: React.ComponentType<{ children: React.ReactNode }>;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */


/**
 * This class handles the global context used by the OPMS.
 */
export class Forms
{
    public initialAuthentication?: IUserResponse | null | undefined;

    /**
     * The base route of the apis (used to create keys for SWR).
     */
    public baseRoute: string;

    /**
     * The name of the organisation running the trial.
     */
    public organisationName: string;

    /**
     * The logo for the organisation running the trial.
     */
    public organisationLogo: string | null;

    /**
     * Basic metadata about the forms available in the OPMS.
     */
    public formMetadata: Dtos.FormMetadata[];

    /**
     * The ServiceStack client used throughout the app.
     * 
     * Allows for requests to be sent to the server side ServiceStack instance.
     */
    public serviceStackClient: JsonServiceClient;

    /**
     * The names of all the Route Parameters used throughout the app.
     * 
     * Allows for the naming of the Route Parameters to be changed.
     */
    public routeParameters: IFormsRouteParameterOptions;

    /**
     * The MUI theme used throughout the app.
     * 
     * Allows for global theming and styling to applied to the app.
     */
    public theme: Theme;

    /**
     * The DTOs used throughout the app.
     * 
     * Allows for typed communication with the server side ServiceStack instance.
     */
    public dtos: Record<string, any>;

    /**
     * The Extensions applied to the OPMS.
     */
    public extensions: IFormsExtension[];

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IFormsOptions) {
        this.initialAuthentication = options?.initialAuthentication;
        this.initialiseExtensions(options?.extensions);
        this.initialiseBaseRoute(options?.baseRoute);
        this.initialiseTrialInformation(options?.organisationOptions);
        this.initialiseFormMetadata(options?.formMetadata);
        this.initialiseServiceStackClient(options?.serviceStackClient);
        this.initialiseDtos(options?.dtos);
        this.initialiseRouteParameters(options?.routeParameters);
        this.initialiseTheme(options?.themeOptions);
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseExtensions(extensions?: IFormsExtension[] | null) {
        this.extensions = extensions ?? [];
    }

    /**
     * This method initialises the base route.
     * @param baseRoute Configured base route.
     */
    private initialiseBaseRoute(baseRoute?: string | null) {
        this.baseRoute = baseRoute ?? '/forms';
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseTrialInformation(trialOptions?: IFormsOrgOptions | null) {
        this.organisationName = trialOptions?.organisationName ?? 'Unknown Organisation';
        this.organisationLogo = trialOptions?.organisationLogo ?? null;
    }

    /**
     * This method initialises the form metadata.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseFormMetadata(formMetadata?: Dtos.FormMetadata[] | null) {
        this.formMetadata = formMetadata ?? [];
    }

    /**
     * This method initialises the ServiceStack client.
     * @param serviceStackClient Configured ServiceStack Client.
     */
    private initialiseServiceStackClient(serviceStackClient?: JsonServiceClient | null) {

        if (serviceStackClient) {
            // If ServiceStack Client has been provided, use it.

            // Check that the ServiceStack Client is of the correct object type.
            if (serviceStackClient instanceof JsonServiceClient) {
                throw new Error("The provided ServiceStack Client was not of the correct type.")
            }

            this.serviceStackClient = serviceStackClient;
        }
        else {
            // If no ServiceStack Client was provided, create a new Servicestack Client.
            this.serviceStackClient = new JsonServiceClient();
        }
    }

    /**
     * This method initialises the DTOs.
     * @param dtos Configured DTOs
     */
    private initialiseDtos(dtos?: Record<string, any> | null) {
        if (dtos) {
            // If DTOs have been provided, use it.
            this.dtos = dtos;
        }
        else {
            // If no DTOs were provided, throw errors.
            throw new Error("There was no DTOs provided.")
        }
    }

    /**
     * This method initialises the Route Parameter Names.
     * @param routeParameters Configured Route Parameter Names.
     */
    private initialiseRouteParameters(routeParameters?: Partial<IFormsRouteParameterOptions> | null) {
        this.routeParameters = {
            formDefinitionId: routeParameters?.formDefinitionId ?? 'formDefinitionId',
            formDefinitionCode: routeParameters?.formDefinitionCode ?? 'formDefinitionCode',
            formId: routeParameters?.formId ?? 'formId',
            formRepeat: routeParameters?.formRepeat ?? 'formRepeat'
        }
    }

    /**
     * This method initialises the Theme.
     * @param themeOptions Configured Theme Options
     */
    private initialiseTheme(themeOptions?: IFormsThemeOptions | null) {

        // Create default MUI Theme Options
        let muiThemeOptions: ThemeOptions = {
            typography: {
                h1: {
                    fontSize: '2rem'
                },
                h2: {
                    fontSize: '1.5rem'
                },
                h3: {
                    fontSize: '1.25rem'
                },
                h4: {
                    fontSize: '1.20rem'
                },
                h5: {
                    fontSize: '1.15rem'
                },
                h6: {
                    fontSize: '1.10rem'
                }
            }
        };

        // Update MUI Theme Options using event function if provided.
        if (themeOptions?.beforeThemeCreated) {
            muiThemeOptions = themeOptions.beforeThemeCreated(muiThemeOptions);
        }

        // Create MUI Theme from MUI Theme Options.
        let muiTheme = createTheme(muiThemeOptions);

        // Update MUI Theme using event function if provided.
        if (themeOptions?.afterThemeCreated) {
            muiTheme = themeOptions.afterThemeCreated(muiTheme);
        }

        // Set MUI Theme;
        this.theme = muiTheme;
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Forms;