import { Paper, Theme, Typography } from '@mui/material';
import React, { FunctionComponent, useContext } from 'react';
import PatientInformation from '../components/patient/PatientInformation';
import MedicalReviewContext from '../contexts/data/MedicalReviewContext';
import ReviewerContext from '../contexts/data/ReviewerContext';
import ContextMedicalReviewQueriesBreadcrumbs from '../components/breadcrumbs/ContextMedicalReviewQueriesBreadcrumbs';
import ContextQueryGrid from '../components/query/ContextQueryGrid';
import { InstitutionContext, PageLayout, PatientContext } from '@ngt/forms-trials';
import {makeStyles} from 'tss-react/mui';

interface IQueriesProps {
}

const useStyles = makeStyles()((theme: Theme) => {
    return ({
        container: {
            padding: theme.spacing(0, 0, 3, 0)
        },
        title: {
            padding: theme.spacing(3, 3, 0)
        },
        subtitle: {
            padding: theme.spacing(0, 3, 3)
        },
        patientInformation: {
            padding: theme.spacing(3, 3, 0)
        },
        buttonGroup: {
            padding: theme.spacing(3),
            textAlign: 'right'
        }
    });
});

const QuerySummary: FunctionComponent<IQueriesProps> = () => {
    const {classes} = useStyles();

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: medicalReview } = useContext(MedicalReviewContext);
    const { data: reviewer } = useContext(ReviewerContext);

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewQueriesBreadcrumbs />}
            heading="Query Summary"
        >
            <Paper
                className={classes.container}
            >
                <div className={classes.patientInformation}>
                    <PatientInformation patient={patient} institution={institution} reviewer={reviewer} medicalReview={medicalReview} />
                </div>
                <Typography variant="h1" className={classes.title}>
                    Query Summary
                </Typography>
                <ContextQueryGrid headerRows />
            </Paper>
        </PageLayout>
    );
};

export default QuerySummary;
