/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode, useContext } from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, PathMatch, Routes } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load forms into the FormByIdContext
 */
import FormByIdResolver, { IFormMapping } from '../../resolver/data/FormByIdResolver';

/**
 * Used to get OPMS settings
 */
import FormsContext from '../../../contexts/FormsContext';
import { IForm, FormDefinitionSingleResponse, FormTypeSingleResponse, LookupCollectionResponse } from '../../../api/dtos';
import { SWRConfiguration } from 'swr';
import { IDataSingleResponse } from '../../..';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export type IFormByIdRouteProps = RouteProps & {
    formDefinitionCode?: string;
    id?: number;

    computedMatch?: PathMatch<string>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    formMapping?: Record<string, IFormMapping>;

    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IForm>, IDataSingleResponse<IForm>>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const FormByIdRoute: FunctionComponent<IFormByIdRouteProps> = ({
    formDefinitionCode,
    id,
    resolveBeforeLoad,
    children,
    formMapping,
    formConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
    lookupConfiguration,
    ...routeProps
}) => {
    const formsContext = useContext(FormsContext)
    const params = routeProps.computedMatch?.params ?? useParams<string>();
    const paramFormIdStr = params[formsContext.routeParameters.formId];
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    let paramId: number | null = null;

    try {
        if (paramFormIdStr) {
            paramId = parseInt(paramFormIdStr);
        }
    }
    catch (error) {
        console.error(`Invalid ID in route: ${paramFormIdStr}`)
    }

    return <Routes>
        <Route
            {...routeProps}
            element={
                <FormByIdResolver
                    formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? ''}
                    id={paramId ?? id ?? null}
                    resolveBeforeLoad={resolveBeforeLoad}
                    formMapping={formMapping}
                    formConfiguration={formConfiguration}
                    formDefinitionConfiguration={formDefinitionConfiguration}
                    formTypeConfiguration={formTypeConfiguration}
                    lookupConfiguration={lookupConfiguration}
                >
                    {children}
                </FormByIdResolver>
            }
        />
    </Routes>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByIdRoute;