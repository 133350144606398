/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Toolbar, Button, Theme, useTheme, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { makeStyles } from '../../styles/makeStyles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from '@ngt/forms';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IBreadcrumb {
    label: React.ReactNode;
    icon: IconDefinition;
    to: string;
}

/**
 * This interface defines the properties for the Breadcrumbs component.
 */
export interface IBreadcrumbsProps {
    breadcrumbs: IBreadcrumb[]
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    breadcrumbs: {
        background: 'transparent !important',
        color: '#585a5b !important',
        display: 'flex !important',
        alignItems: 'center !important'
    },
    navLink: {
        borderRadius: '50px !important',
        fontSize: '0.75rem !important',
        fontWeight: 500,
        textTransform: 'none',
        margin: `${theme.spacing(0.25, 0)} !important`,

        // '&:hover': {
        //     backgroundColor: onlinePatientManagement.colors.secondary[50]
        // }
    },
    navLinkActive: {
        // background: onlinePatientManagement.colors.secondary[50]
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({
    breadcrumbs
}) => {
    const {classes} = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            breadcrumbs: {
                background: 'transparent !important',
                color: '#585a5b !important',
                display: 'flex !important',
                alignItems: 'center !important'
            },
            navLink: {
                borderRadius: '50px !important',
                fontSize: '0.75rem !important',
                fontWeight: 500,
                textTransform: 'none',
                margin: `${theme.spacing(0.25, 0)} !important`,
        
                // '&:hover': {
                //     backgroundColor: onlinePatientManagement.colors.secondary[50]
                // }
            },
            navLinkActive: {
                // background: onlinePatientManagement.colors.secondary[50]
            }
        }
    }, [theme]);

    return (
        <MuiBreadcrumbs
            className={classes.breadcrumbs}
            separator={<FontAwesomeIcon icon={faChevronRight} fixedWidth />}
        >
            {
                breadcrumbs?.map((breadcrumb, i) =>{
                    return (
                        <Button
                            key={i}
                            component={NavLink}
                            to={breadcrumb.to}
                            color="inherit"
                            className={classes.navLink}
                            startIcon={<FontAwesomeIcon icon={breadcrumb.icon} fixedWidth />}
                            size="small"
                            disableRipple
                        >
                            {breadcrumb.label}
                        </Button>
                    );
                })
            }
        </MuiBreadcrumbs>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Breadcrumbs;