/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */
import { IFormsExtension, IUserDto, Forms } from '@ngt/forms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { IMenuItem } from './components/layout/Header';
import TrialsLayout from './components/TrialsLayout';
import TrialsExtensionContext from './contexts/TrialsExtensionContext';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ITrialsExtensionRouteParameterOptions {
    patientStudyNumber: string;
}

export interface ITrialsExtensionOptions {
    /**
     * The logo for the organisation running the trial.
     */
    organisationLogo?: string;

    organisationLogoMobile?: string;

    /**
     * The name of the organisation running the trial.
     */
    organisationName?: string;

    /**
     * The name of the trial.
     */
    trialName?: string;

    /**
     * the id of the trial
     */
    protocolId?: string;

    /**
     * Logo link.
     */
    homePageUrl?: string;

    /**
     * Menu items to show in the header 
     */
    menuItems?: (user: IUserDto | null | undefined, forms: Forms) => Promise<IMenuItem[]>;

    /**
     * Content to be displayed in the site footer. 
     */
    footer?: React.ReactNode;

    routeParameters: ITrialsExtensionRouteParameterOptions;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class handles the global context used by the OPMS.
 */
export class TrialsExtension implements IFormsExtension {
    public ProviderComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    public ContentComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: ITrialsExtensionOptions) {
        this.initialiseProviderComponent(options);
        this.initialiseContentComponent(options);
    }

    private initialiseProviderComponent(options: ITrialsExtensionOptions) {

        this.ProviderComponent = ({ children }) => {
            return (
                <TrialsExtensionContext.Provider value={options}>
                    {children}
                </TrialsExtensionContext.Provider>
            );
        };
    }

    private initialiseContentComponent(options: ITrialsExtensionOptions) {

        this.ContentComponent = ({ children }) => {
            return (
                <TrialsLayout>
                    {children}
                </TrialsLayout>
            );
        };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default TrialsExtension;

