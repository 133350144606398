/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, ReactNode, useContext } from 'react';

/*
 * Used to apply styles and page layout
 */
import { CssBaseline, Container, Paper, Theme, useTheme, Box, Toolbar } from '@mui/material';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for the header layout.
 */
import Header, { IMenuItem } from './layout/Header';
import { FormsContext, RouteLoading, useUser } from '@ngt/forms';
import TrialsExtensionContext from '../contexts/TrialsExtensionContext';
import LayoutActionContext from '../contexts/layout/LayoutActionContext';
import LayoutStateContext, { ILayoutStateContext } from '../contexts/layout/LayoutStateContext';
import { useEffect, useMemo, useState } from 'react';

import { makeStyles } from 'tss-react/mui'
import HeaderV2 from './layout/HeaderV2';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OnlinePatientManagementLayout component.
 */
export interface IMedicalReviewLayoutProps {
    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    paper: {
        marginBottom: theme.spacing(3),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    footer: {
        padding: theme.spacing(3, 3),
        textAlign: 'center',
        color: theme.palette.primary.light,
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides the basic layout for the patient management system.
 * @param param0 component properties.
 */
const MedicalReviewLayout: FunctionComponent<IMedicalReviewLayoutProps> = ({
    children
}) => {
    const {classes} = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            container: {
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            },
            paper: {
                marginBottom: theme.spacing(3),
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
        
                [theme.breakpoints.down('sm')]: {
                    marginBottom: theme.spacing(0),
                }
            },
            footer: {
                padding: theme.spacing(3, 3),
                textAlign: 'center',
                color: theme.palette.primary.light,
            }
        }
    }, [theme]);

    const [layoutState, setLayoutState] = useState<ILayoutStateContext>({ fullWidth: false });

    const layoutActions = useMemo(() => {
        return {
            setLayoutState
        }
    }, [setLayoutState])

    const user = useUser();


    const forms = useContext(FormsContext);
    const { menuItems: menuItemsFn, footer } = useContext(TrialsExtensionContext);

    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

    useEffect(() => {
        const updateMenuItems = async () => {
            if (menuItemsFn) {
                const newMenuItems = await menuItemsFn(user, forms)
                setMenuItems(newMenuItems);
            }
        }

        updateMenuItems();
    }, [menuItemsFn, user, setMenuItems, forms])

    if (!user) {
        return <RouteLoading />;
    }

    let render = (
        <>
            {
                children
            }
            {
                !!footer && (
                    <Box sx={styles.footer}>
                        {footer}
                    </Box>
                )
            }
        </>
    );

    // if (!layoutState.fullWidth) {
    //     render = (
    //         <Container
    //             sx={styles.container}
    //             disableGutters
    //         >
    //             {render}
    //         </Container>
    //     )
    // }

    return (
        <LayoutActionContext.Provider value={layoutActions}>
            <LayoutStateContext.Provider value={layoutState}>
                <CssBaseline />
                {/* <Header menuItems={menuItems} /> */}
                <HeaderV2 
                    menuItems={menuItems} 
                />
                <Toolbar />
                {render}
            </LayoutStateContext.Provider>
        </LayoutActionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewLayout;