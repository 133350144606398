import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { CollapsibleTable, useUser } from '@ngt/forms';
import MedicalReviewExtensionContext from '../contexts/MedicalReviewExtensionContext';
import { Box, Chip, Grid, TextField, Typography, useTheme, Theme, Paper } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import useReviewers from '../hooks/data/useReviewers';
import { useNavigate, useLocation } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { convertMedicalReviewStatusToString } from '../utilities/MedicalReviewStatus';
import { DateTime } from 'luxon';
import { IPatient, PageLayout, useCoordinatingGroups, useCountries, useInstitutions, useMasterGroups, usePatients } from '@ngt/forms-trials';
import { IMedicalReviewPatient } from '../api/dtos';
import { makeStyles } from '../styles/makeStyles';

interface IPatientsProps {
    rowsPerPageOptions?: number[];
    initialPageSize?: number;
}

interface IPatientFilter {
    masterGroupId?: number;
    coordinatingGroupId?: number;
    countryId?: number;
    institutionId?: number;
    reviewerId?: number;
    treatmentArm?: string;
    reviewStatus?: string;
}


const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        backgroundColor: '#987ba7 !important',
        color: `${theme.palette.common.white} !important`,
    },
    container: {
        padding: theme.spacing(3)
    },
    title: {
        padding: theme.spacing(2, 2, 0)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    requiresAttention: {
        color: theme.palette.warning.main,
        fontSize: '1.275rem'
    },
    cell: {
        cursor: 'pointer',

        '&:focus': {
            outline: 'none !important'
        }
    }
}));

const xs = 12;
const sm = 12;
const md = 6;
const lg = 4;
const xl = 4;



const Patients: FunctionComponent<IPatientsProps> = ({
    rowsPerPageOptions,
    initialPageSize
}) => {
    
    const { classes } = useStyles();

    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            chip: {
                backgroundColor: '#987ba7 !important',
                color: `${theme.palette.common.white} !important`,
            },
            container: {
                padding: theme.spacing(3)
            },
            title: {
                padding: theme.spacing(2, 2, 0)
            },
            selectors: {
                padding: theme.spacing(0, 2)
            },
            requiresAttention: {
                color: theme.palette.warning.main,
                fontSize: '1.275rem',
            },
            cell: {
                cursor: 'pointer',
        
                '&:focus': {
                    outline: 'none !important'
                }
            }
        }
    }, [theme]);

    const navigate = useNavigate();
    const location = useLocation();

    const filter: IPatientFilter = useMemo(() => {
        const f = parse(location.search, { ignoreQueryPrefix: true }) as any;

        try {
            return {
                masterGroupId: f.masterGroupId ? parseInt(f.masterGroupId, 10) : undefined,
                coordinatingGroupId: f.coordinatingGroupId ? parseInt(f.coordinatingGroupId, 10) : undefined,
                countryId: f.countryId ? parseInt(f.countryId, 10) : undefined,
                institutionId: f.institutionId ? parseInt(f.institutionId, 10) : undefined,
                reviewerId: f.reviewerId ? parseInt(f.reviewerId, 10) : undefined,
                treatmentArm: f.treatmentArm,
                reviewStatus: f.reviewStatus
            }
        }
        catch {
            return {};
        }
    }, [location.search])

    const { patientColumnsFn, createPatientRouteFn, createPatientsRouteFn } = useContext(MedicalReviewExtensionContext);

    const user = useUser()
    const { data: countries, loading: countriesLoading } = useCountries();
    const { data: masterGroups, loading: masterGroupsLoading } = useMasterGroups();
    const { data: coordinatingGroups, loading: coordinatingGroupsLoading } = useCoordinatingGroups();
    const { data: institutions, loading: institutionsLoading } = useInstitutions();
    const { data: reviewers, loading: reviewersLoading } = useReviewers();
    const { data: patients, loading: patientsLoading } = usePatients();

    const treatmentArms = useMemo(() => {
        return [...Array.from(new Set(patients?.map(patient => (patient as IMedicalReviewPatient).treatmentArm)))];
    }, [patients]);

    const reviewStatus = useMemo(() => {
        return [...Array.from(new Set(patients?.map(patient => convertMedicalReviewStatusToString((patient as IMedicalReviewPatient).reviewStatus)))).sort()];
    }, [patients, convertMedicalReviewStatusToString]);

    const onPatientClick = useCallback((params: GridRowParams) => {
        navigate(createPatientRouteFn(params.row as IPatient));
    }, [navigate, createPatientRouteFn]);

    const patientColumns: GridColDef[] = useMemo(() => {
        if (!patientColumnsFn) {
            return [
                {
                    field: 'studyNumber',
                    headerName: 'Paticipant Number',
                    width: 210
                },
                {
                    field: 'treatmentArm',
                    headerName: 'Treatment Arm',
                    width: 180,
                    hide: true
                },
                {
                    field: 'country',
                    headerName: 'Country',
                    valueGetter: params => countries?.find(c => c.id === institutions?.find(i => i.id === params.row.institutionId)?.countryId)?.name,
                    width: 200,
                    hide: true
                },
                {
                    field: 'institutionCode',
                    headerName: 'Institution',
                    //renderHeader: params => <><FontAwesomeIcon icon={faBuilding} />&nbsp;Code</>,
                    valueGetter: params => institutions?.find(i => i.id === params.row.institutionId)?.code,
                    renderCell: params => { 
                        if (!params.value) {
                            return <div />;
                        }

                        return (
                            <Chip
                                label={<Typography sx={{fontSize: '0.8rem'}}>{params.value}</Typography>}
                                size="small"
                                sx={{
                                    backgroundColor: '#987ba7 !important',
                                    color: `${theme.palette.common.white} !important`,
                                    marginRight: `${theme.spacing(1)} !important`
                                }}
                            />
                        )
                    },
                    width: 150
                },
                {
                    field: 'institutionName',
                    headerName: 'Institution Name',
                    valueGetter: params => institutions?.find(i => i.id === params.row.institutionId)?.name,
                    flex: 1
                },
                {
                    field: 'enteredDate',
                    headerName: 'Randomisation Date',
                    valueGetter: params => params.row.enteredDate ? DateTime.fromISO(params.row.enteredDate).toFormat('dd/MM/yyyy') : undefined,
                    width: 210
                },
                {
                    field: 'medicalReviewers',
                    headerName: 'Reviewers',
                    valueGetter: params => {
                        return params.row.assignedMedicalReviewers?.reduce((a: string, b: number) => {
                            const reviewer = reviewers?.find(x => x.id === b);

                            if (!reviewer) {
                                return a;
                            }

                            if (a !== '') {
                                if (a.toLowerCase().includes(reviewer.name.toLowerCase())) {
                                    return a;
                                }

                                return `${a}, ${reviewer.name}`;
                            }

                            return reviewer.name;
                        }, '')
                    },
                    width: 200
                },
                {
                    field: 'reviewStatus',
                    headerName: 'Status',
                    valueGetter: params => {
                        return convertMedicalReviewStatusToString(params.row.reviewStatus)
                    },
                    width: 150
                },
                {
                    field: 'requiresAttention',
                    headerName: 'Requires Attention',
                    renderHeader: () => <div></div>,
                    valueGetter: params => {
                        if (!params.row.reviewStatus) {
                            return 1;
                        }

                        return params.row.requiresAttention?.reduce((a: number, b: number) => {
                            const reviewer = reviewers?.find(x => x.id === b);

                            if (!reviewer) {
                                return a;
                            }

                            if (reviewer.usernames?.includes(user?.userName!)) {
                                return 1;
                            }

                            return a;
                        }, 0)
                    },
                    renderCell: params => {
                        if (!params.value || params.value === 0) {
                            return <div />;
                        }

                        return (
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="1x"
                                style={{
                                    color: theme.palette.warning.main,
                                    fontSize: '1.275rem'
                                }}
                            />
                        )
                    },
                    align: 'center',
                    width: 100
                }
            ]
        }

        return patientColumnsFn(user, countries ?? [], institutions ?? [], reviewers ?? []) ?? [];
    }, [user, countries, institutions, reviewers, patientColumnsFn, classes])


    const selectedMasterGroup = useMemo(() => {
        return masterGroups?.find(x => x.id === filter.masterGroupId) ?? null;
    }, [filter.masterGroupId, masterGroups]);

    const selectedCoordinatingGroup = useMemo(() => {
        return coordinatingGroups?.find(x => x.id === filter.coordinatingGroupId) ?? null;
    }, [filter.coordinatingGroupId, coordinatingGroups]);

    const selectedCountry = useMemo(() => {
        return countries?.find(x => x.id === filter.countryId) ?? null;
    }, [filter.countryId, countries]);

    const selectedInstitution = useMemo(() => {
        return institutions?.find(x => x.id === filter.institutionId) ?? null;
    }, [filter.institutionId, institutions]);

    const selectedReviewer = useMemo(() => {
        return reviewers?.find(x => x.id === filter.reviewerId) ?? null;
    }, [filter.reviewerId, reviewers]);

    const selectedTreatmentArm = useMemo(() => {
        return treatmentArms?.find(x => x === filter.treatmentArm) ?? null;
    }, [filter.treatmentArm, treatmentArms]);

    const selectedReviewStatus = useMemo(() => {
        return reviewStatus?.find(x => x === filter.reviewStatus) ?? null;
    }, [filter.reviewStatus, reviewStatus]);

    const onMasterGroupFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            masterGroupId: value?.id
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onCoordinatingGroupFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            coordinatingGroupId: value?.id
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onCountryFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            countryId: value?.id
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onInstitutionFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            institutionId: value?.id
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onReviewerFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            reviewerId: value?.id
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onTreatmentArmFilterChange = useCallback((event: ChangeEvent<{}>, value: string | null) => {
        const search = stringify({
            ...filter,
            treatmentArm: !!value && value.length > 0 ? value : undefined
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const onReviewStatusFilterChange = useCallback((event: ChangeEvent<{}>, value: string | null) => {
        const search = stringify({
            ...filter,
            reviewStatus: !!value && value.length > 0 ? value : undefined
        });

        navigate(createPatientsRouteFn(search));
    }, [filter, navigate, createPatientsRouteFn]);

    const filteredPatients = useMemo(() => {
        if (!filter.masterGroupId &&
            !filter.coordinatingGroupId &&
            !filter.countryId &&
            !filter.institutionId &&
            !filter.reviewerId && 
            !filter.treatmentArm &&
            !filter.reviewStatus) {
            return patients;
        }

        return patients?.filter(patient => {
            

            if (filter.masterGroupId ||
                filter.coordinatingGroupId ||
                filter.countryId) {
                const institution = institutions?.find(i => i.id === patient.institutionId);

                if (filter.masterGroupId && institution?.masterGroupId !== filter.masterGroupId) {
                    return false;
                }

                if (filter.coordinatingGroupId && institution?.coordinatingGroupId !== filter.coordinatingGroupId) {
                    return false;
                }

                if (filter.countryId && institution?.countryId !== filter.countryId) {
                    return false;
                }

            }

            if (filter.institutionId && patient?.institutionId !== filter.institutionId) {
                return false;
            }

            if (filter.reviewerId && !(patient as IMedicalReviewPatient)?.assignedMedicalReviewers?.includes(filter.reviewerId)) {
                return false;
            }

            if (filter.treatmentArm && (patient as IMedicalReviewPatient)?.treatmentArm !== filter.treatmentArm) {
                return false;
            }

            if (filter.reviewStatus && convertMedicalReviewStatusToString((patient as IMedicalReviewPatient)?.reviewStatus) !== filter.reviewStatus) {
                return false;
            }

            return true;
        }) ?? [];
    }, [patients, institutions, filter, convertMedicalReviewStatusToString])

    const getClassName = useCallback(() => {
        return classes.cell
    }, [classes.cell])

    return (
        <PageLayout
            heading="Participants"
            breadcrumbs={<></>}
        >
            <Paper
                className={classes.container}
            >
            <Box className={classes.selectors}>
                <Grid container spacing={2}>
                    {
                        !!masterGroups?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="masterGroupId"
                                    options={masterGroups ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={masterGroupsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Master Group" />}
                                    onChange={onMasterGroupFilterChange}
                                    value={selectedMasterGroup}
                                />
                            </Grid>
                        )
                    }
                    {
                        !!coordinatingGroups?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="coordinatingGroupId"
                                    options={coordinatingGroups ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={coordinatingGroupsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Coordinating Group" />}
                                    onChange={onCoordinatingGroupFilterChange}
                                    value={selectedCoordinatingGroup}
                                />
                            </Grid>
                        )
                    }
                    {
                        !!countries?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="countryId"
                                    options={countries ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={countriesLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Country" />}
                                    onChange={onCountryFilterChange}
                                    value={selectedCountry}
                                />
                            </Grid>
                        )
                    }
                    {
                        !!institutions?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="institutionId"
                                    options={institutions ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={institutionsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Institution" />}
                                    onChange={onInstitutionFilterChange}
                                    value={selectedInstitution}
                                />
                            </Grid>
                        )
                    }
                    {
                        !!reviewers?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="reviewerId"
                                    options={reviewers ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={reviewersLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Reviewer" />}
                                    onChange={onReviewerFilterChange}
                                    value={selectedReviewer}
                                />
                            </Grid>
                        )
                    }

                    {
                        !!treatmentArms?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="treatmentArm"
                                    options={treatmentArms ?? []}
                                    getOptionLabel={(option) => option}
                                    fullWidth
                                    loading={patientsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Treatment" />}
                                    onChange={onTreatmentArmFilterChange}
                                    value={selectedTreatmentArm}
                                />
                            </Grid>
                        )
                    }

                    {
                        !!reviewStatus?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="reviewStatus"
                                    options={reviewStatus ?? []}
                                    getOptionLabel={(option) => option}
                                    fullWidth
                                    loading={patientsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Status" />}
                                    onChange={onReviewStatusFilterChange}
                                    value={selectedReviewStatus}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
            <CollapsibleTable
                title="Participants"
                entityName="Participant"
                rows={filteredPatients ?? []}
                loading={patientsLoading}
                columns={patientColumns as any}
                autoHeight
                rowsPerPageOptions={rowsPerPageOptions}
                initialPageSize={initialPageSize}
                hideFooter={!rowsPerPageOptions}
                onRowClick={onPatientClick}
                disableColumnFilter
                getCellClassName={getClassName}
            />
            </Paper>
        </PageLayout>
    );
};

export default Patients;
