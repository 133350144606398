/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import React, { FunctionComponent, useContext } from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import { IInputRenderProps } from '@ngt/forms-core';
import { Theme, Typography } from '@mui/material';
import Markdown from '../../../utility/Markdown';
import { makeStyles } from '../../../../utilities/makeStyles';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { useMemo } from 'react';
import FormLookupContext from '../../../../contexts/form/FormLookupContext';



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = Partial<MuiSelectProps> & IInputRenderProps<string, Dtos.IValidationError>

export type ISelectDisplayProps = TextFieldsProps & {

}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles()((theme: Theme) => ({
    pre: {
        whiteSpace: 'pre-wrap',
        margin: theme.spacing(0)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const SelectDisplay: FunctionComponent<ISelectDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    ...rest
}) => {
    const { classes } = useStyles();

    const lookup = useContext(FormLookupContext);

    const lookupValue = useMemo(() => {
        if (value && lookup) {
            const lookupItem = lookup?.items?.find(item => item.id === value)

            return lookupItem?.value ?? value;
        }

        return value;
    }, [lookup, value])

    if (value === null || value === undefined) {
        return null;
    }

    return (
        <pre
            className={classes.pre}
        >
            <Typography>
                {lookupValue?.toString()}
            </Typography>
        </pre>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SelectDisplay;
