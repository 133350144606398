import { Box, Button, Paper, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import PatientInformation from '../components/patient/PatientInformation';
import { FormDefinitionsContext, FormTypesContext } from '@ngt/forms';
import MedicalReviewList from '../components/medicalReview/MedicalReviewList';
import CreateMedicalReviewDialog from '../components/dialog/CreateMedicalReviewDialog';
import ContextPatientBreadcrumbs from '../components/breadcrumbs/ContextPatientBreadcrumbs';
import ReviewersContext from '../contexts/data/ReviewersContext';
import MedicalReviewsContext from '../contexts/data/MedicalReviewsContext';
import MedicalReviewDefinitionsContext from '../contexts/configuration/MedicalReviewDefinitionsContext';
import useContextPermissions from '../hooks/utility/useContextPermissions';
import { MedicalReviewPermission } from '../api/dtos';
import { InstitutionContext, PatientContext, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from '../styles/makeStyles';

interface IPatientProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        //padding: theme.spacing(3)
    },
    title: {
        padding: theme.spacing(3)
    },
    patientInformation: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    }
}));


const Patient: FunctionComponent<IPatientProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            container: {
                //padding: theme.spacing(3)
            },
            title: {
                padding: theme.spacing(3)
            },
            patientInformation: {
                padding: theme.spacing(3)
            },
            buttonGroup: {
                padding: theme.spacing(3),
                textAlign: 'right'
            }
        }
    }, [theme]);

    const [createMedicalReviewDialogOpen, setCreateMedicalReviewDialogOpen] = useState(false);

    const onCreateMedicalReviewDialogOpen = useCallback(() => {
        setCreateMedicalReviewDialogOpen(true);
    }, [setCreateMedicalReviewDialogOpen])

    const onCreateMedicalReviewDialogClose = useCallback(() => {
        setCreateMedicalReviewDialogOpen(false);
    }, [setCreateMedicalReviewDialogOpen])

    const { data: patient} = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: medicalReviewDefinitions } = useContext(MedicalReviewDefinitionsContext);
    const { data: medicalReviews } = useContext(MedicalReviewsContext);
    const { data: reviewers } = useContext(ReviewersContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);

    const { data: [ canCreateMedicalReview ] } = useContextPermissions([MedicalReviewPermission.CreateMedicalReview]);

    return (
        <PageLayout
            breadcrumbs={<ContextPatientBreadcrumbs />}
            heading="Patient Summary"
        >
            <Paper
                className={classes.container}
            >
                <Box sx={styles.patientInformation}>
                    <PatientInformation patient={patient} institution={institution} />
                </Box>
                <MedicalReviewList
                    patient={patient ?? undefined}
                    reviewers={reviewers}
                    medicalReviews={medicalReviews}
                    medicalReviewDefinitions={medicalReviewDefinitions}
                />
                <CreateMedicalReviewDialog
                    open={createMedicalReviewDialogOpen}
                    onClose={onCreateMedicalReviewDialogClose}
                    patient={patient ?? undefined}
                    medicalReviewDefinitions={medicalReviewDefinitions}
                    formTypes={formTypes}
                    institution={institution ?? undefined}
                    reviewers={reviewers}
                    formDefinitions={formDefinitions ?? undefined}
                />
                <Box sx={styles.buttonGroup}>
                    {
                        canCreateMedicalReview && (
                            <Button variant="contained" color="primary" onClick={onCreateMedicalReviewDialogOpen}>
                                Create Medical Review
                            </Button>
                        )
                    }
                </Box>
            </Paper>
        </PageLayout>
    );
};

export default Patient;
