/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { Box, Container, Theme, Typography } from '@mui/material';

import { makeStyles } from '../../styles/makeStyles';
/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */


export interface IPageLayoutProps {
    breadcrumbs?: React.ReactNode;
    heading?: string;
    children: React.ReactNode;  
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    padding: {
        padding: theme.spacing(0, 0, 2, 0)
    },
    breadcrumbs: {
        display: 'flex',
    },
    titleContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        background: '#dde0e3',
        marginBottom: theme.spacing(2),

        // [theme.breakpoints.down('sm')]: {
        //     flexDirection: 'column',
        //     margin: theme.spacing(2)
        // }
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: '1.75rem',
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const PageLayout: React.FunctionComponent<IPageLayoutProps> = ({
    breadcrumbs,
    heading,
    children,
}) => {
    const {classes} = useStyles();

    const contain = true;

    const Component = contain ? Container : Box;

    return (
        <>
            {
                !!breadcrumbs && (
                    <>
                        <Box className={classes.titleContainer}>
                            <Container className={classes.breadcrumbs}>
                                {breadcrumbs}
                            </Container>
                        </Box>
                        {
                            heading && (
                                <Container>
                                    <Typography
                                        className={classes.padding}
                                        color="secondary"
                                        variant="h1"
                                    >
                                        {heading}
                                    </Typography>
                                </Container>
                            )
                        }
                    </>
                )
            }
            <Container
                className={classes.container}
            >
                {children as any}
            </Container>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PageLayout;
