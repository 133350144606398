/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { IInputRenderProps } from '@ngt/forms-core';
import { Chip, Theme, useTheme } from '@mui/material';
import { IValidationError } from '@ngt/forms';
import pluralize from 'pluralize';
import { makeStyles } from '../../../../styles/makeStyles';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type SymbolFieldProps = IInputRenderProps<any[], IValidationError>

export interface ICountDisplayProps extends SymbolFieldProps {
    entity: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        backgroundColor: '#987ba7 !important',
        color: `${theme.palette.common.white} !important`,
        marginRight: `${theme.spacing(1)} !important`
    },

}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const CountDisplay: React.FunctionComponent<ICountDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    entity,
    ...rest
}) => {
    const theme = useTheme();
    const {classes} = useStyles();

    let count = 0;

    if (value && value instanceof Array) {
        count = value.length;
    }

    return (
        <Chip
            label={`${pluralize(entity, count, true)}`}
            className={classes.chip}
            size="small"
        />
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CountDisplay;
